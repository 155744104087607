import React from 'react';

import './Message.css';

import ReactEmoji from 'react-emoji';

const Message = ({ message: { dices, results, total, text, user }, name }) => {
  let isSentByCurrentUser = false;

  const trimmedName = name.trim().toLowerCase();
  let detail = ''

  if (results) detail = results.length > 1 ? ':' + results.join('+') + '=' : ''

  if (user === trimmedName) {
    isSentByCurrentUser = true;
  }

  return (
    isSentByCurrentUser
      ? (
        <div className="messageContainer justifyEnd">
          <span className="sentText pr-10">{trimmedName}</span>
          {text
            ? <div className="messageBox backgroundBlue">
              <span className="messageText colorWhite">{ReactEmoji.emojify(text)}</span>
            </div>
            : <>
              {dices.split('/').map((d, i) => <span className='dice' key={i}>{d}</span>)}

              <span className='detail'>{detail}</span><span className='result'>{total}</span>
            </>
          }
        </div>
      )
      : (
        <div className="messageContainer justifyStart">
          {text
            ? <div className="messageBox backgroundLight">
              <span className="messageText colorDark">{ReactEmoji.emojify(text)}</span>
            </div>
            : <>
              {dices.split('/').map((d, i) => <span className='dice' key={i}>{d}</span>)}

              <span className='detail'>{detail}</span><span className='result'>{total}</span>
            </>
          }
          <span className="sentText pl-10">{user}</span>
        </div>
      )
  );
}

export default Message;