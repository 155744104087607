import React, { useState, useEffect } from "react";
import queryString from 'query-string';
import io from "socket.io-client";

import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import Info from '../Info/Info';

import './Chat.css';

// const ENDPOINT = 'http://localhost:3333/';
const ENDPOINT = ('_self' in React.createElement('div')) ? 'http://localhost:3333/' : window.location.origin;

let socket;

const Chat = ({ location }) => {
  const [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [lancer, setLancer] = useState({dices: [], timer: 0});

  useEffect(() => {
    const { name, room } = queryString.parse(location.search);

    socket = io(ENDPOINT);

    setRoom(room);
    setName(name)

    socket.emit('join', { name, room }, (error) => {
      if (error) {
        alert(error);
      }
    });
  }, [location.search]);

  useEffect(() => {
    socket.on('message', message => {
      console.log(message)
      setMessages(messages => [...messages, message]);
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();

    if (message) {
      socket.emit('sendMessage', message, () => setMessage(''));
    }
  }

  const addDice = (n) => {
    if (lancer.dices.length < 8) {
      setLancer(lancer => {
        if (lancer.timer) clearTimeout(lancer.timer)
        const dices = [...lancer.dices, n]
        return {
          dices: [...dices],
          timer: setTimeout(() => {
            console.log(dices)
            socket.emit('sendDices', dices.join('/'), () => setLancer({dices: [], timer: 0}))
          }, 1000)
        }
      })
    }
  }

  return (
    <div className="outerContainer">
      <div className="container">
        <InfoBar room={room} name={name} />
        <Info open={lancer.dices.length} >{lancer.dices.map((d, i) => <span className='dice' key={i}>{d}</span> )}</Info>
        <Messages messages={messages} name={name} />
        <Input message={message} setMessage={setMessage} addDice={addDice} sendMessage={sendMessage} />
      </div>
      <TextContainer users={users} />
    </div>
  );
}

export default Chat;
