import React from 'react';

import './Input.css';

const Input = ({ setMessage, sendMessage, addDice, message }) => (
  <>
    <div className='dices'>
      <button onClick={() => addDice(2)}>2</button>
      <button onClick={() => addDice(4)}>4</button>
      <button onClick={() => addDice(6)}>6</button>
      <button onClick={() => addDice(8)}>8</button>
    </div>
    <div className='dices'>
      <button onClick={() => addDice(10)}>10</button>
      <button onClick={() => addDice(12)}>12</button>
      <button onClick={() => addDice(20)}>20</button>
      <button onClick={() => addDice(100)}>100</button>
    </div>
  <form className="form">
    <input
      className="input"
      type="text"
      placeholder="Votre message..."
      value={message}
      onChange={({ target: { value } }) => setMessage(value)}
      onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
    />
    <button className="sendButton" onClick={e => sendMessage(e)}>Envoyer</button>
  </form>
  </>
)

export default Input;