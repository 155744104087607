import React, { useState } from 'react';
import { Link } from "react-router-dom";

import './Join.css';

const names = ['🎲']

export default function SignIn() {
  const [name, setName] = useState('')
  const [room, setRoom] = useState('')
  const [error, setError] = useState('')

  function handleClick(e) {
    if (!name || !room || names.indexOf(name.toLowerCase()) !== -1) {
      setError("Pseudo/Salle invalide")
      e.preventDefault()
      return
    }
    setError('')
  }
  return (
    <div className="joinOuterContainer">
      <div className="joinInnerContainer">
        <h1 className="heading">Rejoindre une salle</h1>
        <div>
          <input placeholder="Pseudo" className="joinInput" type="text" onChange={(event) => setName(event.target.value)} />
        </div>
        <div>
          <input placeholder="Salle" className="joinInput mt-20" type="text" onChange={(event) => setRoom(event.target.value)} />
        </div>
        <Link onClick={handleClick} to={`/chat?name=${name}&room=${room}`}>
          <button className={'button mt-20'} type="submit">Connexion</button>
        </Link>
        <div className='error'>{error}</div>
      </div>
    </div>
  );
}
