import React from 'react';

import onlineIcon from '../../icons/onlineIcon.png';

import './TextContainer.css';

const TextContainer = ({ users }) => (
  <div className="textContainer">
    <div>
      <h1>Le maître des <span role="img" aria-label="emoji">🎲</span> vous salue !</h1>
      {users ? <h2>Vous êtes dans la salle <em>{users[0].room}</em></h2> : null}
    </div>
    {
      users
        ? (
          <div>
            <h2>Les présents sont :</h2>
            <div className="activeContainer">
              <h3>
                {users.map(({name}) => (
                  <div key={name} className="activeItem">
                    {name}
                    <img alt="Online Icon" src={onlineIcon}/>
                  </div>
                ))}
              </h3>
            </div>
          </div>
        )
        : null
    }
  </div>
);

export default TextContainer;