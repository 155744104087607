import React from 'react';

import './Info.css'

const Info = ({open, children}) => {

    if (!open) return null

    return (
        <div className='info'>{children}</div>
    )
}

export default Info